/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import Loader from 'components/Loader';

const Header = () => {
  const { pathname } = useLocation();
  const heightScreen = window.innerHeight;
  const widthScreen = window.innerWidth;

  const [fixed, setFixed] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [orientation, setOrientation] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [medias, setMedias] = useState([]);
  const [menuMedias, setMenuMedias] = useState([]);

  const handleOrientation = () => {
    if (!window.screen.orientation.angle) {
      setOrientation('portrait');
    } else {
      setOrientation('landscape');
    }
  };

  const toggleHomeLink = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setMenuVisible(false);
  };

  const toggleVisible = () => {
    const scrollPosition = window.scrollY;
    const isPastWindowY = scrollPosition > heightScreen;

    setFixed(isPastWindowY);
  };

  const scrollWithOffsetTo30 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = 30;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const scrollWithOffsetTo40 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = -40;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const scrollWithOffsetTo70 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = -70;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const scrollWithOffsetTo90 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = -90;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const scrollWithOffsetTo100 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchMedia = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}wp/v2/media?order=asc&include=143,144`)
        .then(response => {
          setMedias(response.data);
        })
        .catch(error => {
          // console.error(error);
        });
    };

    fetchMedia();
  }, []);

  useEffect(() => {
    if (medias.length > 0) {
      const mediaInfos = medias.map(media => {
        return {
          id: media.id,
          alt: media.alt_text,
          thumbnail: media.media_details.sizes.thumbnail
            ? media.media_details.sizes.thumbnail.source_url
            : 'no thumbnail url',
        };
      });

      setMenuMedias(mediaInfos);
      setIsLoading(false);
    }
  }, [medias]);

  useEffect(() => {
    window.addEventListener('resize', handleOrientation);
    return () => window.removeEventListener('resize', handleOrientation);
  }, []);

  window.addEventListener('scroll', toggleVisible);

  return isLoading ? (
    <Loader />
  ) : (
    <header
      className={
        fixed
          ? 'header-container header-container-fixed'
          : pathname === '/blog'
          ? 'header-container header-container-blog'
          : // : pathname === '/blog' && fixed
          // ? 'header-container-blog header-container-blog-fixed'
          orientation === 'landscape' ||
            orientation === 568 ||
            orientation === 640 ||
            orientation === 667 ||
            orientation === 700 ||
            orientation === 736 ||
            orientation === 740 ||
            orientation === 812 ||
            orientation === 844 ||
            orientation === 851 ||
            orientation === 915
          ? 'header-container header-container-landscape-mobile'
          : orientation === 'landscape' || orientation === 1024
          ? 'header-container header-container-landscape-tablet'
          : fixed &&
            (orientation === 'landscape' ||
              orientation === 568 ||
              orientation === 640 ||
              orientation === 667 ||
              orientation === 700 ||
              orientation === 736 ||
              orientation === 740 ||
              orientation === 812 ||
              orientation === 844 ||
              orientation === 851 ||
              orientation === 915)
          ? 'header-container-fixed header-container-landscape-fixed-mobile'
          : fixed && (orientation === 'landscape' || orientation === 1024)
          ? 'header-container-fixed header-container-landscape-fixed-tablet'
          : pathname === '/blog' &&
            (orientation === 'landscape' ||
              orientation === 568 ||
              orientation === 640 ||
              orientation === 667 ||
              orientation === 700 ||
              orientation === 736 ||
              orientation === 740 ||
              orientation === 812 ||
              orientation === 844 ||
              orientation === 851 ||
              orientation === 915)
          ? 'header-container-blog header-container-landscape-blog-mobile'
          : pathname === '/blog' && (orientation === 'landscape' || orientation === 1024)
          ? 'header-container-blog header-container-landscape-blog-tablet'
          : 'header-container'
      }
      style={{
        transition: 'all 0.3s ease-in',
      }}
    >
      <NavLink to="/accueil">
        <div className="logo-container">
          <div className="logo-imageCtn">
            <img
              className="logo-img"
              src={fixed ? menuMedias[0].thumbnail : menuMedias[1].thumbnail}
              alt={fixed ? menuMedias[0].alt : menuMedias[1].alt}
              width="150"
              height="auto"
            />
          </div>
          <h1 className="logo-title">Julie Eggenspieler</h1>
        </div>
      </NavLink>

      <button
        className="burger-container"
        type="button"
        onClick={() => {
          setMenuVisible(!menuVisible);
        }}
      >
        {menuVisible ? <div className="cross">X</div> : <div className="burger" />}
      </button>

      <div className={menuVisible ? 'contact-nav-container-mobile' : 'contact-nav-container'}>
        <div className="contact-container">
          <div className="contact-list">
            <a className="phone-container" href="tel:0698322302">
              <FontAwesomeIcon className="fa fa-phone" icon={faPhone} />
              <span className="span-phone">06 98 32 23 02</span>
            </a>
            <a className="email-container" href="mailto:julie.eggen@gmail.com">
              <FontAwesomeIcon className="fa fa-email" icon={faEnvelope} />
              <span className="span-email">julie.eggen@gmail.com</span>
            </a>
            <p className="address-container">
              <FontAwesomeIcon className="fa fa-dot" icon={faLocationDot} />
              <span className="span-address">
                12, bis rue du Grand Cerf <br /> 57000 Metz
              </span>
            </p>
          </div>
        </div>

        <div className="navigation-container">
          <ul className="nav-list">
            <li className="nav-item">
              <HashLink smooth to="/accueil" onClick={() => toggleHomeLink()} scroll={el => scrollWithOffsetTo0(el)}>
                Accueil
              </HashLink>
            </li>

            <li className="nav-item">
              <HashLink
                smooth
                to="/accueil#présentation"
                onClick={() => {
                  setMenuVisible(false);
                }}
              >
                Présentation
              </HashLink>
            </li>

            <li className="nav-item">
              <HashLink
                smooth
                to="/accueil#mon-parcours"
                onClick={() => {
                  setMenuVisible(false);
                }}
                scroll={el => scrollWithOffsetTo40(el)}
              >
                Parcours
              </HashLink>
            </li>

            <li className="nav-item">
              <HashLink
                smooth
                to="/accueil#séances"
                onClick={() => {
                  setMenuVisible(false);
                }}
                scroll={el => scrollWithOffsetTo70(el)}
              >
                Séances
              </HashLink>
            </li>

            <li className="nav-item">
              {widthScreen > 1190 && widthScreen < 1399 ? (
                <HashLink
                  smooth
                  to="/accueil#at"
                  onClick={() => setMenuVisible(false)}
                  scroll={el => scrollWithOffsetTo100(el)}
                >
                  AT
                </HashLink>
              ) : (
                <HashLink
                  smooth
                  to="/accueil#at"
                  onClick={() => setMenuVisible(false)}
                  scroll={el => scrollWithOffsetTo90(el)}
                >
                  Analyse transactionnelle
                </HashLink>
              )}
            </li>

            <li className="nav-item">
              <HashLink
                smooth
                to="/accueil#faq"
                onClick={() => {
                  setMenuVisible(false);
                }}
                scroll={el => scrollWithOffsetTo100(el)}
              >
                Questions fréquentes
              </HashLink>
            </li>

            <li className="nav-item">
              <HashLink
                smooth
                to="/accueil#témoignages"
                onClick={() => {
                  setMenuVisible(false);
                }}
                scroll={el => scrollWithOffsetTo30(el)}
              >
                Témoignages
              </HashLink>
            </li>

            <li className="nav-item">
              <HashLink
                smooth
                to="/accueil#contact-et-rdv"
                onClick={() => {
                  setMenuVisible(false);
                }}
                scroll={el => scrollWithOffsetTo90(el)}
              >
                Contact & RDV
              </HashLink>
            </li>

            <li className="nav-item blog-link">
              <NavLink to="/blog" onClick={() => setMenuVisible(false)}>
                Blog
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
