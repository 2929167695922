import { circle1, circle2, circle3 } from 'images';

const Loader = () => (
  <div className="loader-container">
    <img className="circle circle1" src={circle1} alt="cercle bleu" />
    <img className="circle circle2" src={circle2} alt="cercle rouge" />
    <img className="circle circle3" src={circle3} alt="cercle jaune" />
  </div>
);

export default Loader;
